import React from 'react';

import RequireLogin from '@domui-components/Auth/RequireLogin';
import MemberSearch from '@domui-presenters/web/pages/MemberSearch';

const MemberSearchPage = () => (
  <RequireLogin>
    <MemberSearch />
  </RequireLogin>
);
export default MemberSearchPage;
