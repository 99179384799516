import React, { useEffect } from 'react';

import Select, { SelectProps, useOptions } from '../Select';

import { useFetchAllDistricts } from '@domui-hooks/useFetchAllDistricts';

export const useDistrictsOptions = () => {
  const { data, loading, error, fetchAllDistricts } = useFetchAllDistricts();

  useEffect(() => {
    fetchAllDistricts();
  }, []);

  const options = data
    ?.map((district: { riDistrictId: number }) => ({
      label: String(district.riDistrictId),
      value: String(district.riDistrictId),
    }))
    .sort(
      (
        { value: firstDistrict }: { value: Number },
        { value: secondDistrict }: { value: Number }
      ) => Number(firstDistrict) - Number(secondDistrict)
    );

  return {
    data,
    options,
    loading,
    error: error?.message,
  };
};

export const DistrictsSelect: React.FC<Omit<
  SelectProps,
  'options'
>> = props => {
  const useContextualRoleOptions: useOptions = () => {
    return useDistrictsOptions();
  };
  return <Select {...props} options={useContextualRoleOptions} />;
};
