import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

// Custom hook to handle API calls
export const useAllDistrictClubs = () => {
  const [data, setData] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);

  const allDistrictClubs = async (
    pageNumber: number,
    pageSize: number,
    orgId: string,
    requestBody: DynamicData
  ) => {
    try {
      if (requestBody) {
        setLoading(true);
        setError(null);
        const wfService = new Organization();
        const response = await wfService.getAllDistrictClubs(
          pageNumber,
          pageSize,
          orgId,
          requestBody
        );
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, allDistrictClubs };
};
