import React, { useEffect } from 'react';

// import { useFormikContext } from 'formik';

import { useDistrictsOptions } from './DistrictsSelect';
import Select, { SelectProps, useOptions } from '../Select';

// import { MemberSearchFormValues } from '@domain/clubs';

// import { getClubDisplayName, getClubValue } from '@domui-utils/club-name';
import { useAllDistrictClubs } from '@domui-hooks/useAllDistrictClubs';
import { getClubValue } from '@domui-utils/club-name';
import { getRotaryYear } from '@domui-utils/datetime';

import { useTranslation } from '@external/react-i18next';

// import { ClubTypeFilterValue } from '@typings/graphql';

export type SelectTerminationReasonProps = Omit<SelectProps, 'options'> & {
  clubType: string;
};

export const useClubOptions: useOptions = (
  input: string,
  riDistrictId?: number
  // clubType?: ClubTypeFilterValue | null | string
) => {
  const { data } = useDistrictsOptions();

  // const { values } = useFormikContext<MemberSearchFormValues>();
  // const { t } = useTranslation();
  // const [clubId] =
  //   values.club && !values.district ? values.club.split('-') : [''];

  const {
    data: clubData,
    loading,
    error,
    allDistrictClubs,
  } = useAllDistrictClubs();
  const getIdFromDistrictList = (selectedRiDistrictId?: number) => {
    const selectedDistrict = data?.find(
      (district: { riDistrictId: number }) =>
        Number(district.riDistrictId) === Number(selectedRiDistrictId)
    );
    return selectedDistrict?.id || ''; // Return id or empty string if not found
  };

  const orgId = getIdFromDistrictList(riDistrictId);

  // Fetch data when the component mounts or when `riDistrictId` changes
  const payload = {
    rotaryYear: getRotaryYear(),
  };
  useEffect(() => {
    if (orgId) allDistrictClubs(1, 200, orgId || '', payload); // Fetch clubs
  }, [orgId]);
  // const { data, loading, error } = useFetchClubSelectOptions(
  //   input,
  //   riDistrictId,
  //   clubId,
  //   clubType as ClubTypeFilterValue | null
  // );
  if (!riDistrictId) {
    return {
      options: [],
      loading: false,
      error: undefined,
    };
  }

  const options = clubData?.districtClubs?.map(
    (club: {
      id: string;
      riClubId: string;
      startDate: string;
      endDate: string;
      name: string;
      status: string;
      clubType: string;
    }) => ({
      label: `${club?.name} (${club?.clubType})`,
      value: getClubValue(club?.id, club?.clubType),
    })
  );
  return {
    options,
    loading,
    error: error?.message,
  };
};

export const ClubSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();
  return (
    <Select
      {...props}
      placeholder={t('club-select.find-by-club-name', 'Find by club name')}
      options={useClubOptions}
      // react-select filters options by full match with option.value
      // since we use external search we don't need to filter options within react-select
      filterOption={() => true}
    />
  );
};
