import React, { useEffect } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { every, first, isEmpty, last } from 'lodash';

import { Button } from '@components/Button';
import MemberLocationSelect from '@components/Formik/LocationSelector/MemberLocationSelect';
import TextField from '@components/Formik/TextField';
import Loading from '@components/Loading';

import validationSchema from './validation';

import { getRotaryYear } from '@utils/datetime';
import { addLocalisationToPath } from '@utils/localized-navigate';

import { ClubSelect } from '@domui-components/Formik/Select/ClubSelect';
import { DistrictsSelect } from '@domui-components/Formik/Select/DistrictsSelect';
import { MemberTypeSelect } from '@domui-components/Formik/Select/MemberTypeSelect';
import { RoleSelect } from '@domui-components/Formik/Select/RoleSelect';
import {
  CLUB_TYPE_MAPPING,
  MemberSearchFormValues,
  ROTARY_ROTARACT_ROLES_MAPPING,
} from '@domui-domain/clubs';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchStates } from '@domui-hooks/useFetchStates';
import {
  addQueryParamsToURL,
  getMemberParamsFromURL,
  validateMemberParamsFromURL,
} from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

type MemberSearchFormProps = {
  onSubmit: (value: MemberSearchFormValues) => void;
  setResetFlag: (flag: boolean) => void;
  handleReset?: () => void;
};

const MemberSearchForm: React.FC<MemberSearchFormProps> = ({
  onSubmit,
  handleReset,
  setResetFlag,
}) => {
  const { t } = useTranslation();
  const RY = getRotaryYear();
  const {
    data: countriesList,
    loading: loadingCountries,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const {
    data: statesData,
    loading: loadingStates,
    fetchStates,
  } = useFetchStates();
  useEffect(() => {
    fetchStates();
  }, []);

  if (loadingCountries || loadingStates) {
    return <Loading />;
  }

  const getCountryData = (country?: string) => {
    const countryData = countriesList?.find(
      ({ countryName, id }: { countryName: string; id: string }) =>
        id === country || countryName === country
    );

    return {
      countryName: countryData?.countryName,
      countryId: countryData?.id,
    };
  };

  const getStateId = (country?: string, stateName?: string) => {
    return statesData?.find(
      ({
        id: stateId,
        countryId,
        stateName: nameOfState,
      }: {
        id: string;
        countryId: string;
        stateName: string;
      }) =>
        (stateName === stateId || stateName === nameOfState) &&
        country === countryId
    )?.id;
  };

  const isAllValuesEmpty = (values: MemberSearchFormValues) =>
    every(values, isEmpty);

  const initialValuesfromURL = getMemberParamsFromURL();
  const validatedInitialValues = validateMemberParamsFromURL(
    initialValuesfromURL
  );

  const handleSubmit = (
    values: MemberSearchFormValues,
    helpers: FormikHelpers<MemberSearchFormValues>
  ) => {
    if (isAllValuesEmpty(values)) {
      return;
    }

    const [club, clubType] = values?.club
      ? [
          values.club.slice(0, values.club.lastIndexOf('-')),
          values.club.split('-').pop(),
        ]
      : ['', ''];

    const memberClubType = clubType ? CLUB_TYPE_MAPPING[clubType] : '';

    const memberTypeValue = values.memberTypes || '';

    const rotaractEnabledRoles =
      values.clubRole && ROTARY_ROTARACT_ROLES_MAPPING[values.clubRole];

    const isRotaractRolesAvailable = !!rotaractEnabledRoles;

    // Get city/state/country by location string
    const parsedLocation = values.location?.split(', ') || [];

    const city = first(parsedLocation);
    const country = last(parsedLocation);
    const state = parsedLocation.length > 2 ? parsedLocation?.[1] : '';

    const { countryName, countryId } = getCountryData(country);

    const formSubmitValues: MemberSearchFormValues = {
      ...values,
      location: undefined,
      city,
      country: countryName,
      stateId: getStateId(countryId, state),
      club,
      memberClubType,
      memberTypes: memberTypeValue,
    };

    if (values.districtRole) {
      formSubmitValues.districtRoles = [values.districtRole];
    } else if (isRotaractRolesAvailable) {
      formSubmitValues.clubRoles = rotaractEnabledRoles;
    } else if (values.clubRole) {
      formSubmitValues.clubRoles = [values.clubRole];
    }

    // Deleting duplicate values before submitting form.
    delete formSubmitValues.clubRole;
    delete formSubmitValues.districtRole;
    onSubmit(formSubmitValues);
    helpers.setSubmitting(false);
    setResetFlag(false);

    const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
    const baseMemberURL = `${appUrl}${addLocalisationToPath(
      '/domui/member-search'
    )}`;
    const { location, clubRole } = values;
    const validFormValues = validateMemberParamsFromURL({
      ...formSubmitValues,
      location,
      clubRole,
    });
    const formattedURL = addQueryParamsToURL(baseMemberURL, validFormValues);
    window.history.pushState({}, '', formattedURL);
  };

  const initialValues: MemberSearchFormValues = {
    name: '',
    email: '',
    location: '',
    city: '',
    country: '',
    stateId: '',
    district: null,
    club: '',
    districtRole: '',
    districtRoles: [],
    clubRole: '',
    clubRoles: [],
    memberClubType: '',
    memberTypes: '',
  };

  return (
    <>
      <h2 className="mb-8">
        {t('search.members.filters-title', 'Find a member')}
      </h2>
      <Formik
        initialValues={
          isEmpty(validatedInitialValues)
            ? initialValues
            : validatedInitialValues
        }
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ values, isSubmitting, resetForm }) => {
          const { district } = values;

          const organizationTypes = [
            'Rotary Club',
            'District',
            'Rotaract Club',
          ];

          const isSubmitDisabled = isAllValuesEmpty(values) || isSubmitting;

          return (
            <Form
              onReset={() => {
                resetForm({
                  values: initialValues,
                });
                handleReset?.();
                const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
                const memberSearchURL = `${appUrl}${addLocalisationToPath(
                  '/domui/member-search'
                )}`;
                window.history.pushState({}, '', memberSearchURL);
              }}
            >
              <TextField
                name="name"
                label={t('member-search.form.name.label', 'Name')}
                placeholder={t(
                  'member-search.form.name.placeholder',
                  'Find by name (optional)'
                )}
                resettable
                icon="magnifier"
              />

              <TextField
                name="email"
                label={t('member-search.form.email.label', 'Email')}
                placeholder={t(
                  'member-search.form.email.placeholder',
                  'Find by email (optional)'
                )}
                resettable
                icon="magnifier"
              />

              <h4 className="mt-10 mb-5 alternate">
                {t('member-search.form.filters-heading', 'Filter by')}
              </h4>
              <MemberLocationSelect
                name="location"
                label={t('member-search.form.location.label', 'Location')}
                resettable
              />
              <DistrictsSelect
                name="district"
                label={t('search.members.filters-district-label', 'District')}
                clearable
              />
              <ClubSelect
                name="club"
                clearable
                label={t('search.members.filters-club-label', 'Club')}
                riDistrictId={Number(district)}
              />
              <MemberTypeSelect
                name="memberTypes"
                label={t(
                  'search.members.filters-memberTypes-label',
                  'Member Type'
                )}
                clearable
              />
              <RoleSelect
                name="clubRole"
                label={t('search.members.filters-role-label', 'Role')}
                organizationType={organizationTypes}
                validOn={`${RY}-07-01`}
                isGlobalMemberSearch
                clearable
              />
              <Button full disabled={isSubmitDisabled} className="mt-4">
                {t('search.members.submit-label', 'Search')}
              </Button>
              <Button
                type="reset"
                textNormal
                capitalize
                full
                className="mt-6 center"
              >
                {t('search.members.reset-label', 'Reset all filters')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MemberSearchForm;
