import { useEffect, useMemo } from 'react';

import { useOfficerRoles } from './useOfficerRoles';

import { useSessionStorage } from '@repositories/storage/hooks';

import { DynamicData } from '@domui-domain/type';

export const useDominoOfficerRoles = () => {
  const { getStorageData, updateStorage } = useSessionStorage<DynamicData>();

  const {
    data: fetchData,
    loading,
    error,
    fetchOfficerRoles,
  } = useOfficerRoles();

  // useEffect(() => {
  //   fetchOfficerRoles();
  // }, [riDistrictId, districtId]);
  const userCacheId = `domino-user--officer-roles`;

  useEffect(() => {
    const storageData = getStorageData(userCacheId);

    if (!storageData) {
      fetchOfficerRoles();
    }
  }, []);

  const data = useMemo(() => {
    const storageData = getStorageData(userCacheId);
    if (!loading && fetchData) {
      updateStorage(fetchData, userCacheId);
      return fetchData;
    }

    return storageData?.data;
  }, [fetchData, loading]);
  return { data, loading, error };
};
