/* eslint-disable @typescript-eslint/no-explicit-any */

import { RolesForSelectRotaractOfficers } from '@domain/clubs/types';

import { mapLeadershipRoles } from '@domui-use-cases/districts/helpers/mapLeadershipRoles';
import { getClubRoleTValue } from '@domui-utils/getClubRoleTValue';
import { isRotaractClub } from '@domui-utils/isRotaractClub';
import { useDominoOfficerRoles } from 'src/domui/hooks/useDominoOfficerRoles';

import { useTranslation } from '@external/react-i18next';

export const useRoleOptionsForMemberSearch = (
  organizationType: string[],
  validOn: string | number,
  isGlobalMemberSearch?: boolean
) => {
  const { t } = useTranslation();

  // const {
  //   data,
  //   loading,
  //   error,
  //   organizationTypeRoleMapping,
  // } = useOrganizationTypeRoleMapping();

  // useEffect(() => {
  //   if (clubId) {
  //     organizationTypeRoleMapping(clubId);
  //   }
  // }, [clubId]);

  const { data: rolesData, loading, error } = useDominoOfficerRoles();

  const data = mapLeadershipRoles(
    { organizationType, validOn },
    rolesData?.response
  );
  const rotaractOfficerRoles = Object.values(RolesForSelectRotaractOfficers);
  return {
    options:
      data
        ?.filter(({ name, type }: { name: string; type: string }) => {
          if (isGlobalMemberSearch) {
            return !rotaractOfficerRoles.includes(
              name.trim() as RolesForSelectRotaractOfficers
            );
          }
          return !isRotaractClub(type) || isRotaractClub(type);
        })
        .map(
          ({ id, name, type }: { id: string; name: string; type: string }) => ({
            id,
            label: getClubRoleTValue(t, name),
            value: name,
            type,
          })
        ) || [],
    loading,
    error: error?.message,
  };
};
