import { TFunction } from 'i18next';
import * as yup from 'yup';

import { emailSchema } from '@components/Formik/validation/fieldDefinitions';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    email: emailSchema(t),
  });

export default validationSchema;
