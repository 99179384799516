import React from 'react';

import { useField } from 'formik';

import Select, { SelectOptions, SelectProps, useOptions } from '../Select';

// import { useRoleOptions } from '@use-cases/clubs';
import { useRoleOptionsForMemberSearch } from 'src/domui/use-cases/clubs/hooks/useRoleOptionsForMemberSearch';

export type SelectTerminationReasonProps = Omit<SelectProps, 'options'> & {
  clubType: string;
};

export type RoleNamesSelectProps = Omit<SelectProps, 'options'> & {
  organizationType: string[];
  validOn: number | string;
  isGlobalMemberSearch?: boolean;
};

export const RoleSelect: React.FC<RoleNamesSelectProps> = props => {
  const { onChange, organizationType, validOn, isGlobalMemberSearch } = props;

  const [districtRoleField, , districtRoleHelper] = useField('districtRole');

  const useContextualRoleOptions: useOptions = () =>
    useRoleOptionsForMemberSearch(
      organizationType,
      validOn,
      isGlobalMemberSearch
    );

  const onChangeHandler = (
    option: string,
    options?: useOptions | SelectOptions
  ) => {
    const selectedItem = (options as SelectOptions).find(
      item => item.value === option
    );
    if (selectedItem?.type === 'District') {
      districtRoleHelper.setValue(selectedItem.value);
    } else {
      districtRoleField.value && districtRoleHelper.setValue('');
    }
    onChange?.(option, options);
  };
  return (
    <Select
      {...props}
      onChange={onChangeHandler}
      options={useContextualRoleOptions}
    />
  );
};
