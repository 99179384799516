import * as R from 'ramda';

export enum ClubTypeEnum {
  RotaryClub = 'Rotary Club',
  RotaractClub = 'Rotaract Club',
  SatelliteClub = 'Satellite Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
}

export const isRotaractClub = (clubType?: string | null): boolean =>
  R.equals(clubType, ClubTypeEnum.RotaractClub);
