import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Metadata from '@domui-utils/workflow/metadata';

// Custom hook to handle API calls
export const useFetchStates = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(null);

  const fetchStates = async (code?: string) => {
    // Clear error before new request
    try {
      setLoading(true);
      setError(null);
      const wfService = new Metadata();
      const response = await wfService.getStateData(code || '');
      setData(response);
      setLoading(false);
    } catch (err) {
      setError(err as DynamicData);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchStates };
};
