import React, { useEffect, useRef } from 'react';

import { omit, values } from 'lodash';

import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import MemberSearchResult from './MemberSearchResult';
import MemberSearchSummary from './MemberSearchSummary';

import { useErrorHandling } from '@use-cases/notifications';

import { MemberSearchFormValues } from '@domui-domain/clubs';
import { useFindMemberSearch } from '@domui-hooks/useFindMemberSearch';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

import { MemberSearchResultFragment } from '@typings/operations';

type MemberSearchListProps = {
  filters: MemberSearchFormValues;
  currentPage: number;
  resetFlag: boolean;
  setCurrentPage: (page: number) => void;
};

const MemberSearchList: React.FC<MemberSearchListProps> = ({
  filters,
  currentPage,
  setCurrentPage,
  resetFlag,
}) => {
  const pageSize = 10;
  const topOfTheList = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { fireTrackingEvent } = useAnalytics();

  // We have to delete the location from graphql query as it was not available in UI.
  if (filters.location) {
    // eslint-disable-next-line no-param-reassign
    delete filters.location;
  }
  // const [club] = filters.club ? filters.club.split('-') : ['', ''];
  const { data, loading, error, findMemberSearch } = useFindMemberSearch();
  useErrorHandling(error?.message, !!error, 'member-search.error');

  let numberOfFilters = values(
    omit(filters, [
      'name',
      'email',
      'memberClubType',
      'city',
      'country',
      'stateId',
    ])
  ).filter(
    item =>
      Boolean(item) &&
      (typeof item === 'string' || Array.isArray(item)) &&
      item.length
  ).length;

  if (Boolean(filters.city) || Boolean(filters.country)) {
    numberOfFilters += 1;
  }

  const noFiltersError = t(
    'member-search.error.no-filters',
    'Please provide at least one search parameter.'
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };
  const userInteracted = useRef(0);

  useEffect(() => {
    if (userInteracted.current < 5) {
      userInteracted.current += 1;
      return;
    }
    const {
      club,
      memberTypes,
      stateId,
      country,
      clubRoles,
      districtRoles,
      ...restFilters
    } = filters;

    const updatedFilters = {
      ...restFilters,
      stateId: stateId || '',
      country: country || '',
      role: clubRoles?.[0] || districtRoles?.[0] || '',
      district: Number(filters?.district),
      memberType: memberTypes || '',
      clubId: club || null,
      organizationTypePk: null,
    };
    if (!updatedFilters?.location) delete updatedFilters?.location;
    if (Object.values(filters).filter(Boolean).length === 0) return;
    if (resetFlag) return;
    findMemberSearch(currentPage, pageSize, updatedFilters);
  }, [filters]);
  useEffect(() => {
    // Fire analytics event when we have the initial search results and a search term.
    if (data && currentPage === 1) {
      if (data?.totalCount !== 0) {
        fireTrackingEvent('searchResults', {
          searchTerm: filters.name,
          searchTotalResults: data?.totalCount,
          searchType: 'Member Search',
          searchFilters: filters,
        });
      } else {
        // Separate out the event if zero results
        fireTrackingEvent('searchResultsEmpty', {
          searchTerm: filters.name,
          searchType: 'Member Search',
          searchFilters: filters,
        });
      }
    }

    // Scroll to top when data is updated
    topOfTheList.current?.scrollIntoView();
  }, [data]);

  if (numberOfFilters < 0 || !data) {
    return <p>{noFiltersError}</p>;
  }

  if (loading) {
    return <Loading />;
  }

  if (resetFlag) return null;

  return (
    <>
      <ResultsList
        listId="search-results-list"
        summary={
          <MemberSearchSummary
            showing={data?.results?.length}
            total={data?.totalCount}
            name={filters.name}
            email={filters.email}
            location={filters.location}
            numberOfFilters={numberOfFilters}
            ref={topOfTheList}
          />
        }
      >
        {data?.results?.map(
          (
            member: JSX.IntrinsicAttributes &
              MemberSearchResultFragment & {
                searchRank: number;
                firstName: string;
                middleName: string;
                lastName: string;
              } & {
                children?: React.ReactNode | undefined;
              },
            index: number
          ) => (
            <ResultsListItem key={member?.email || index}>
              {/* Add the "search rank" to results (which number link is clicked in results) */}
              <MemberSearchResult
                {...member}
                searchRank={pageSize * (currentPage - 1) + (index + 1)}
              />
            </ResultsListItem>
          )
        )}
      </ResultsList>
      {data?.totalCount > 0 && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={data?.totalCount}
          pageHandler={pageHandler}
        />
      )}{' '}
    </>
  );
};

export default MemberSearchList;
