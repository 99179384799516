import { TFunction } from 'i18next';

import { getClubTypeName } from '@utils/getClubTypeName';

import { ClubType } from '@typings/graphql';

// fullName comes from DIS and can contain `${clubType} of ` which needs to be removed
export const trimClubFullName = (fullName: string) => {
  let trimmedName = fullName;

  const fullNameStart: string[] = [
    'Rotary Club of ',
    'Satellite Club of ',
    'Rotaract Club of ',
    'Rotaract Satellite Club of ',
  ];
  const sliceHandler = (length: number) => {
    trimmedName = fullName.slice(length);
  };

  fullNameStart.find(
    (nameStart: string) =>
      fullName.includes(nameStart) && sliceHandler(nameStart.length)
  );

  return trimmedName;
};

export const getClubDisplayName = (
  fullName: string,
  clubType: ClubType,
  t: TFunction
): string => `${trimClubFullName(fullName)} (${getClubTypeName(t, clubType)})`;

export const getClubValue = (riClubId: string, clubType: string): string =>
  `${riClubId}-${clubType.trim()}`;
